const IDENTITY_KEY = 'yablo_identity';

const STATUS_PERSISTED = 'persisted';
const STATUS_REGISTERED = 'registered';

const getIdentity = () => {
    try {
        const identity = localStorage.getItem(IDENTITY_KEY);

        if (identity) {
            return JSON.parse(identity);
        }

        return null;
    }
    catch (e) {
        return null;
    }
}

const getIdentityFromUrl = (href) => {
    try {
        const url = new URL(href);

        const identity = {
            email: url.searchParams.get('email')?.toLowerCase(),
            firstName: url.searchParams.get('fname'),
            lastName: url.searchParams.get('lname'),
        };

        if (identity.email) {
            return identity;
        }

        return null;
    }
    catch (e) {
        return null;
    }
}

const persistIdentity = ({ email, firstName, lastName, jobTitle, company, status = null }) => {
    try {
        let identity = getIdentity();

        if (!identity) {
            identity = {
                id: email?.toLowerCase(),
                status: STATUS_PERSISTED
            };
        }

        identity.email = email?.toLowerCase();
        identity.firstName = firstName;
        identity.lastName = lastName;
        identity.jobTitle = jobTitle;
        identity.company = company;

        if (status) {
            identity.status = status;
        }

        localStorage.setItem(IDENTITY_KEY, JSON.stringify(identity));

        return identity;
    }
    catch (e) {
        return null;
    }
}

const registerIdentityFromUrl = () => {
    const identity = getIdentityFromUrl(window.location.href);

    if (identity) {
        return registerIdentity(identity);
    }

    return null;
};

const registerIdentity = ({ email, firstName, lastName, jobTitle, company }) => {
    const env = process.env.NODE_ENV;

    if (env !== 'production') {
        return;
    }

    if (email) {
        // const utmContext = getUtmContext();

        // const lead = {
        //     email: email?.toLowerCase(),
        //     name: `${firstName} ${lastName}`,
        //     companyName: company,
        //     jobTitle: jobTitle,
        //     utmCampaign: utmContext?.campaign,
        //     utmSource: utmContext?.source,
        // };

        // fetch(`https://app.yablo.io/api/marketing/leads`, {
        //     method: 'POST',
        //     mode: 'cors',
        //     headers: {
        //         "Content-Type": "application/json",
        //         'Access-Control-Allow-Origin' : '*'
        //     },
        //     body: JSON.stringify(lead)
        // });

        persistIdentity({ email, firstName, lastName, jobTitle, company, status: STATUS_REGISTERED });
    }
}

export {
    getIdentity,
    persistIdentity,
    registerIdentity,
    registerIdentityFromUrl
};