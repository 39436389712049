const ITEM_KEY = 'utm_context';

const addUtmContextToUrl = (url, userEmail = null, userName = null) => {
    try {
        const uri = new URL(url);

        const utmContext = getUtmContext();

        if (utmContext) {
            if (utmContext.source) {
                uri.searchParams.append('utm_source', utmContext.source);
            }

            if (utmContext.medium) {
                uri.searchParams.append('utm_medium', utmContext.medium);
            }

            if (utmContext.campaign) {
                uri.searchParams.append('utm_campaign', utmContext.campaign);
            }
        }
        
        if (userEmail) {
            uri.searchParams.append('email', userEmail);
        }

        if (userName) {
            uri.searchParams.append('name', userName);
        }

        return uri.toString();
    }
    catch (e) {
        console.info(e);

        return url;
    }
};

const getUtmContext = () => {
    try {
        const utm = localStorage.getItem(ITEM_KEY);

        if (utm) {
            return JSON.parse(utm);
        }

        return null;
    }
    catch (e) {
        return null;
    }
};

const getUtmContextFromUrl = (href) => {
    try {
        const url = new URL(href);

        const utmContext = {
            source: url.searchParams.get('utm_source'),
            medium: url.searchParams.get('utm_medium'),
            campaign: url.searchParams.get('utm_campaign'),
            batch: url.searchParams.get('utm_batch')
        };

        if (utmContext && utmContext.source) {
            return utmContext;
        }

        return null;
    }
    catch (e) {
        return null;
    }
}

const persistUtmContext = () => {
    try {
        const utmContext = getUtmContextFromUrl(window.location.href);

        if (utmContext) {
            localStorage.setItem(ITEM_KEY, JSON.stringify(utmContext));
        }

        return utmContext;
    }
    catch (e) { 
        return null;
    }
};

export {
    addUtmContextToUrl,
    getUtmContext,
    persistUtmContext
};